main ul {
  list-style-type: none;
  column-count: 2;
  padding: 0;
}

path.hexagon {
  transition: stroke 0.3s;
  animation: fadein 1s forwards;
  opacity: 0;
  stroke: #303030;
  stroke-width: 2px;
}

path.hexagon:hover {
  stroke: white;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

a:link,
a:visited {
  color: inherit;
  font-weight: bold;
}

main {
  max-width: 800px;
}

.globe {
  display: block;
  margin: 0 auto;
}

svg,
g,
text {
  font-family: inherit;
  font-size: inherit;
}

.grid line {
  stroke: #555;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}

h1 {
  font-style: italic;
}

.active {
  font-weight: bold;
}
